const isHover = (mouse, element) => {

        const x = mouse.clientX
        const y = mouse.clientY

        const pos = element.getBoundingClientRect();

        return (pos.left < x && pos.right > x && pos.top < y && pos.bottom > y)
    
}

export default isHover