export default () => {
  // Immagini recenti in homepage, switch delle thumbnail a seconda se l'immagine è portrait o square
  const recents = [...document.querySelectorAll(".recent__item img")];

  for (let i = 0; i < recents.length; i++) {
    const r = recents[i]
    if (r.getAttribute('data-square-img') !== '' && r.getAttribute('data-type') === 'square') {
      r.src = r.getAttribute('data-square-img')
    }

    if (r.getAttribute('data-portrait-img') !== '' && r.getAttribute('data-type') === 'portrait') {
      r.src = r.getAttribute('data-portrait-img')
    }
  }

  // Slider homepage, switch delle immagini se siamo su smartphone
  
  const getRatio = () =>  window.innerWidth / window.innerHeight < 1 ? 'portrait' : 'landscape'
  const ratio = getRatio()
  if (ratio === 'portrait') {
    const sliderImages = [...document.querySelectorAll('.home .slider img')];

    for (let j = 0; j < sliderImages.length; j++) {
      const slide = sliderImages[j]

      if (slide.getAttribute('data-portrait-img') !== '') {
        slide.src = slide.getAttribute('data-portrait-img')
      }
    }
  }

  window.addEventListener("orientationchange", function() {
    window.location.href = window.location.href
  })
  
};
