export default () => {
  const button = document.querySelector(".header__toggle");
  if (button === null) return;

  button.addEventListener('click', () => {

    const { classList } = document.body
    classList.contains('nav-open') ? classList.remove('nav-open') : classList.add('nav-open');

  })
  
};
