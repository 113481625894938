import isHover from "./isHover";
import isTouch from "./isTouch";
import Displacer from './displacer'
import imagesLoaded from 'imagesloaded'

Displacer.prototype.undistort = function() {

  TweenLite.to(this.mat.uniforms.dispFactor, 1.5, {
    value: 0.0,
    ease: "Power1.easeOut"
  });

}

export default () => {

  if (isTouch()) return;


  const wrapper = document.querySelector(".hot-text");
  if (wrapper === null) return;

  // Create wrapper for canvas
  const hotspotWrapper = document.createElement('div');
  hotspotWrapper.classList.add('hotspot-canvas');
  document.querySelector('.supah-scroll').appendChild(hotspotWrapper);

  // DOM elements
  const hotspots = Array.from(wrapper.querySelectorAll("strong")).map(link => ({
    link,
    image: link.querySelector('img'),
  }))
  let slider = null;
  let visible = false;

  const init = () => {

    wrapper.addEventListener('mousemove', watch)

  }

  const watch = (e) => {

    const target = hotspots.filter(hotspot => isHover(e, hotspot.link))[0]

    // if not hovering a link return
    if (target !== undefined) {
      show(target, e)
    } else {
      hide(target)
    }

  }

  const show = (hotspot, e) => {

    if (!visible) {
      visible = true;

      slider = new Displacer({
        parent: hotspotWrapper,
        image: hotspot.image,
        initialDisplacement: .3,
      })

      slider.undistort()

    }

    hotspotWrapper.style.setProperty('--mouse-x', e.pageX + "px");
    hotspotWrapper.style.setProperty('--mouse-y', e.pageY + "px");
    
  }


  const hide = () => {

    if (visible) {
      visible = false;

      // Clean ThreeJS
     slider.renderer.forceContextLoss();
     slider.renderer.context = null;
     slider.renderer.domElement.remove()
     slider.renderer.domElement = null;
     slider.renderer = null;
      
    }

  }

  imagesLoaded(wrapper.querySelectorAll("img"), init);
};
