const addAnimation = (selector, type, options) => {
  const elements = document.querySelectorAll(selector);
  if (elements === null) return;

  elements.forEach(el => {
    el.setAttribute('data-sal', type);

    if (options === undefined) return;

    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        el.setAttribute(`data-sal-${key}`, options[key]);
      }
    }

  });
};

export default () => {

  // GENERAL
  addAnimation('.main-content p, .recent__item .section-header', 'fade', {
    duration: '800',
    easing: 'ease-out'
  })

  addAnimation('.video, .main-content img', 'zoom-in', {
    duration: '800',
    easing: 'ease-out'
  })

};