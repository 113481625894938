import Displacer from './displacer'
import imagesLoaded from 'imagesloaded'

export default () => {

    // no slider no problem
    const el = document.getElementById('slider');
    if (el === null) return;


    // DOM elements
    let images = Array.from(el.querySelectorAll('img'));
    let pagButtons = Array.from(document.getElementById('pagination').querySelectorAll('button'));
    let slider = null;

    const slideWrapper = document.getElementById('slider');
    const slideTitleEl = document.getElementById('slide-title');
    const slideSubtitleEl = document.getElementById('slide-subtitle');
    const slideLinkEl = document.getElementById('slide-link');
    

    /*------------------------------
    Swiping
    ------------------------------*/
    const tollerance = window.innerWidth * 0.1
    let dragging = false
    let dragStart = 0
    let dragEnd = 0
    let isAnimating = false 

    const mouseDown = (e) => {
        dragging = true
        dragStart = dragEnd = e.clientX || e.touches[0].clientX
    }

    const mouseUp = () => {
        dragging = false
        if (!isAnimating) {
            const activeButton = document.querySelector('#pagination .active')
            const buttons = document.querySelectorAll('#pagination button')
            const index = [].indexOf.call(buttons, activeButton)
            let next;

            if (dragStart - tollerance > dragEnd) {  
                next = index + 1
            } else if (dragStart + tollerance < dragEnd) {
                next = index - 1
            }

            if (next == buttons.length) {
                next = 0
            } else if (next == -1) {
                next = buttons.length - 1
            }

            if (next != undefined) {
                buttons[next].click()
                isAnimating = true
                setTimeout(() => {
                    isAnimating = false
                }, 800)
            }
        }
    }

    const mouseMove = (e) => {  
        if (dragging) {
            dragEnd = e.clientX || e.touches[0].clientX
        }
    }


    const init = () => {
        slider = new Displacer({
            parent: el,
            image: images[0]
        });

        // Buttons listeners
        pagButtons.forEach(b => b.addEventListener('click', () => update(b)));

        // Swiping listeners
        slideWrapper.addEventListener('mousedown', mouseDown)
        slideWrapper.addEventListener('mouseup', mouseUp)
        slideWrapper.addEventListener('mousemove', mouseMove)
        slideWrapper.addEventListener('touchstart', mouseDown)
        slideWrapper.addEventListener('touchend', mouseUp)
        slideWrapper.addEventListener('touchmove', mouseMove)
        
        // Initial click
        setTimeout(() => {
            pagButtons[0].click()
        }, 150)
    }

    const update = (button) => {

        if (!slider.isAnimating) {

            slider.isAnimating = true;

            // switch active
            pagButtons.forEach(b => b.classList.remove('active'))
            button.className = 'active';

            let slideId = parseInt(button.dataset.slide, 10);

            const newImage = images[slideId].getAttribute('src')

            slider.update(newImage)

            const nextSlideTitle = document.querySelectorAll(`[data-slide-title="${slideId}"]`)[0].innerHTML;
            const nextSlideSubtitle = document.querySelectorAll(`[data-slide-subtitle="${slideId}"]`)[0].innerHTML;
            const nextSlideLink = document.querySelectorAll(`[data-slide-link="${slideId}"]`)[0].innerHTML;

            TweenLite.fromTo(slideTitleEl, 0.7,
                {
                    autoAlpha: 1,
                    x: 0
                },
                {
                    autoAlpha: 0,
                    x: -20,
                    ease: 'Expo.easeIn',
                    onComplete: function () {
                        slideTitleEl.innerHTML = nextSlideTitle;

                        TweenLite.to(slideTitleEl, 0.7, {
                            autoAlpha: 1,
                            x: 0,
                        })
                    }
                });

            TweenLite.fromTo(slideSubtitleEl, 0.7,
                {
                    autoAlpha: 1,
                    x: 0
                },
                {
                    autoAlpha: 0,
                    x: -20,
                    ease: 'Expo.easeIn',
                    onComplete: function () {
                        slideSubtitleEl.innerHTML = nextSlideSubtitle;

                        TweenLite.to(slideSubtitleEl, 0.7, {
                            autoAlpha: 1,
                            x: 0,
                            delay: 0.1,
                        })
                    }
                });

            slideLinkEl.setAttribute('href', nextSlideLink);

        }

    }

    imagesLoaded(el.querySelectorAll('img'), init);

}

