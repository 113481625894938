/*------------------------------
SupahScroll
------------------------------*/
class SupahScroll {
	constructor(options) {
		this.opt = options || {}
		this.el = this.opt.el ? this.opt.el : '.supah-scroll'
		this.speed = this.opt.speed ? this.opt.speed : 0.1
		this.init()
	}

	init() {
		this.scrollY = 0
		this.supahScroll = document.querySelectorAll(this.el)[0]
		this.events()
		this.resize()
		this.animate()
	}

	resize() {
		document.body.style.height = `${this.supahScroll.getBoundingClientRect().height}px`
	}

	animate() {
		this.scrollY += (window.scrollY - this.scrollY) * this.speed
		this.supahScroll.style.transform = `translate3d(0,${-Math.floor(this.scrollY)}px,0)`
		this.raf = requestAnimationFrame(this.animate.bind(this))
	}

	events() {
		window.addEventListener('load', this.resize.bind(this))
		window.addEventListener('resize', this.resize.bind(this))
	}
}

export default SupahScroll