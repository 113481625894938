const THREE = require('three');

function displacementSlider({ parent, image, initialDisplacement }) {
  let vertex = `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
  `;

  let fragment = `
      
      varying vec2 vUv;

      uniform sampler2D currentImage;
      uniform sampler2D nextImage;

      uniform float dispFactor;

      void main() {

          vec2 uv = vUv;
          vec4 _currentImage;
          vec4 _nextImage;
          float intensity = 0.4;

          vec4 orig1 = texture2D(currentImage, uv);
          vec4 orig2 = texture2D(nextImage, uv);
          
          _currentImage = texture2D(currentImage, vec2(uv.x, uv.y + dispFactor * (orig2 * intensity)));

          _nextImage = texture2D(nextImage, vec2(uv.x, uv.y + (1.0 - dispFactor) * (orig1 * intensity)));

          vec4 finalTexture = mix(_currentImage, _nextImage, dispFactor);

          gl_FragColor = finalTexture;

      }
  `;

  this.isAnimating = false;

  this.initialDisplacement = initialDisplacement ? initialDisplacement : 0.0;

  this.width = image.clientWidth;
  this.height = image.clientHeight;

  this.renderer = new THREE.WebGLRenderer({
    antialias: false,
    alpha: true
  });

  this.renderer.setPixelRatio(window.devicePixelRatio);
  // renderer.setClearColor( 0x3B3B3B, 1.0 );
  this.renderer.setSize(this.width, this.height);
  parent.appendChild(this.renderer.domElement);

  this.loader = new THREE.TextureLoader();
  this.loader.crossOrigin = "anonymous";

  this.image = this.loader.load(image.getAttribute("src") + "?v=" + Date.now());
  this.image.magFilter = this.image.minFilter = THREE.LinearFilter;
  this.image.anisotropy = this.renderer.capabilities.getMaxAnisotropy();

  this.scene = new THREE.Scene();
  // this.scene.background = new THREE.Color(0x3b3b3b);
  this.camera = new THREE.OrthographicCamera(
    this.width / -2,
    this.width / 2,
    this.height / 2,
    this.height / -2,
    1,
    1000
  );

  this.camera.position.z = 1;

  this.mat = new THREE.ShaderMaterial({
    uniforms: {
      dispFactor: { type: "f", value: this.initialDisplacement },
      currentImage: { type: "t", value: this.image },
      nextImage: { type: "t", value: this.image }
    },
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    opacity: 1.0
  });

  this.geometry = new THREE.PlaneBufferGeometry(this.width, this.height, 1);
  this.object = new THREE.Mesh(this.geometry, this.mat);
  this.object.position.set(0, 0, 0);
  this.scene.add(this.object);

  window.addEventListener("resize", () => {
    this.width = image.clientWidth;
    this.height = image.clientHeight;
    if (this.renderer !== null) {
      this.renderer.setSize(this.width, this.height);
    }
  });

  let animate = () => {
    requestAnimationFrame(animate);

    if (this.renderer !== null) {
      this.renderer.render(this.scene, this.camera);
    }
  };
  animate();
}

displacementSlider.prototype.update = function(newImage) {

  // prepare image
  const image = this.loader.load(newImage, () => {
    image.magFilter = image.minFilter = THREE.LinearFilter;
    image.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
  
    this.isAnimating = true;
  
    this.mat.uniforms.nextImage.value = image;
    this.mat.uniforms.nextImage.needsUpdate = true;
  
    TweenLite.to(this.mat.uniforms.dispFactor, 1.2, {
      value: 1,
      ease: "Expo.easeInOut",
      onComplete: () => {
        this.mat.uniforms.currentImage.value = image;
        this.mat.uniforms.currentImage.needsUpdate = true;
        this.mat.uniforms.dispFactor.value = 0.0;
        this.isAnimating = false;
      }
    });
  });
  
  

}

displacementSlider.prototype.undistort = function() {

  TweenLite.to(this.mat.uniforms.dispFactor, 1.5, {
    value: 0.0,
    ease: "Power1.easeOut"
  });

}

export default displacementSlider;