import Displacer from "./displacer"
import imagesLoaded from 'imagesloaded'
import TweenMax, { Power3 } from 'gsap'

// Helpers
const getSet = (source, propertyName) => [
  ...new Set(source.map(p => p[propertyName]).flat().filter(c => c !== ""))
];

// Init
export default () => {
  // if not projects page, return
  if (!document.body.classList.contains("page-template-projects")) return;

  // DOM Elements

  const filterEl = document.querySelector(".filters");
  const navEl = filterEl.querySelector(".filters__nav");
  const resultsEl = document.querySelector(".results");
  let resultItems = [];
  let displaceholders = [];
  let sliders = [];

  // number of products in view => number of canvases
  const windowWidth = window.innerWidth;
  let view = 6;
  if (windowWidth < 767) {
    view = 2;
  } else if (windowWidth < 1500) {
    view = 4;
  }

  //   First render
  let firstRender = true;

  // Understand the data
  const categories = getSet(projectsJSON, "category");
  const tempHierarchy = categories.map(category => {
    const filteredProjects = projectsJSON.filter(p =>
      p.category.includes(category)
    );
    let subcategories = getSet(filteredProjects, "subcategory");

    if (category == 'All') {
      subcategories = []
    }

    return {
      category,
      subcategories
    };
  });

  // Ordino Hierarchy
  const hierarchy = []
  hierarchy.push(tempHierarchy.find(a => a.category === 'All'))
  if (tempHierarchy.find(a => a.category === 'Design') !== undefined) {
    hierarchy.push(tempHierarchy.find(a => a.category === 'Design'))
  }
  if (tempHierarchy.find(a => a.category === 'Architecture') !== undefined) {
    hierarchy.push(tempHierarchy.find(a => a.category === 'Architecture'))
  }
  if (tempHierarchy.find(a => a.category === 'Interior') !== undefined) {
    hierarchy.push(tempHierarchy.find(a => a.category === 'Interior'))
  }

  /*
      MAKE FILTERS
  */

  const renderSubcategies = item => {
    const element = document.createElement("ul");
    element.classList.add("filters__subnav");
    element.setAttribute("data-parent", item.category);

    item.subcategories.forEach(sub => {
      const subEl = document.createElement("li");
      subEl.innerHTML = sub;
      subEl.setAttribute("data-name", sub);
      subEl.addEventListener("click", () => filterSubcategory(subEl));
      element.appendChild(subEl);
    });

    filterEl.appendChild(element);
  };

  const renderCategories = () => {
    hierarchy.forEach((item, index) => {
      window.console.log(index, ' - ', item)
      // create element
      const element = document.createElement("li");
      element.innerHTML = item.category;
      element.setAttribute("data-name", item.category); // set name
      element.addEventListener("click", () => filterCategory(item.category));
      // Click on latest
      if (item.subcategories.length) renderSubcategies(item);
      navEl.appendChild(element);
    });
  };

  //
  renderCategories();

  /*
      RENDER DISCPLACER
  */

   const initDisplacer = () => {
    displaceholders = document.querySelectorAll(".displaceholder");
  
    displaceholders.forEach(parent => {
      const image = parent.querySelector("img");
      
  
      imagesLoaded(image, () => {
        const slider = new Displacer({
          parent,
          image
        });
  
        sliders.push(slider);
      });
    });
  };

   const updateDisplacer = newProjects => {
    // update data
    displaceholders.forEach((parent, index) => {
      const newProject = newProjects[index];
  
      setTimeout(() => {
        parent.setAttribute("href", newProject.permalink);
  
        
        const image = parent.querySelector("img");
        const title = parent.querySelector(".project-thumbnail__name");
        const client = parent.querySelector(".project-thumbnail__client");
        
        // image.setAttribute("src", newProject.thumbnail);
        // sliders[index].update(newProject.thumbnail);

        title.innerText = newProject.title;
        client.innerText = newProject.client;

        if (window.supahscroll !== undefined) {
          window.supahscroll.resize()
        }

        TweenMax.to(image, 1, {
          opacity: 0,
          onComplete: () => {
            image.setAttribute("src", newProject.thumbnail);
            TweenMax.to(image, 1, {
              opacity: 1,
            })
          }
        })

        TweenMax.fromTo([title, client], 1, {
          opacity: 0
        }, {
          opacity: 1
        })

      }, index * 500 / newProjects.length);
    });
  };

  /*
      RENDER PROJECTS
  */

 const htmlEntity = str => {
  let m = str.match(/&#[0-9]{4};|&#[0-9]{3};|&#[0-9]{2};/g)
  for (let i = 0; i < m.length; i++) {
    let t = m[i].replace('&#', '').replace(';', '')
    str = str.replace(m[i], String.fromCharCode(t))
  }    
  return str
}


  const renderInView = projects => {
    if (firstRender) {
      firstRender = false;
      const template = ({ title, client, permalink, thumbnail }) => `
        <a
        class="project-thumbnail displaceholder"
        href="${permalink}"
        >
            <img src="${thumbnail}" alt="Thumbnail" />
            <div class="project-thumbnail__info">
                <h3 class="project-thumbnail__name">${htmlEntity(title)}</h3>
                <span class="project-thumbnail__client">${htmlEntity(client)}</span>
            </div>
        </a>
    `;

      const html = projects.map(template).join("");
      resultsEl.innerHTML = html;

      initDisplacer();
    } else {
      updateDisplacer(projects);
    }
  };

  const renderOutView = projects => {
    // remove old projects
    resultItems.forEach(item => item.remove());

    // replace with new projects
    resultItems = projects.map(project => {
      const element = document.createElement("a");
      element.classList.add("project-thumbnail");
      element.setAttribute("href", project.permalink);
      element.innerHTML = `
            <img src="${project.thumbnail}" alt="Thumbnail" />
            <div class="project-thumbnail__info">
                <h3 class="project-thumbnail__name">${htmlEntity(project.title)}</h3>
                <span class="project-thumbnail__client">${htmlEntity(project.client)}</span>
            </div>
        `;

      resultsEl.appendChild(element);

      return element;
    });
  };

  const renderResults = results => {

    const inView = results.slice(0, view);
    const outView = results.slice(view);

    renderInView(inView);
    renderOutView(outView);

    const projectItems = document.querySelectorAll('.project-thumbnail')
    
    // Nascondo i canvas dei progetti che non appartengono ai risultati
    TweenMax.to(projectItems, 1, {
      autoAlpha: 1,
      ease: Power3.easeInOut
    })
    for (let i = results.length; i < projectItems.length; i++) {
      TweenMax.to(projectItems[i], 1, {
        autoAlpha: 0,
        ease: Power3.easeInOut
      })
    }
    
  };

  /*
      FILTERING
  */

  const filterCategory = targetCategory => {
    // remove all actives
    const actives = document.querySelectorAll(".active");
    actives.forEach(a => a.classList.remove("active"));

    // get elements
    const catEl = document.querySelector(`[data-name="${targetCategory}"]`);
    const subcatEl = document.querySelector(
      `[data-parent="${targetCategory}"]`
    );

    // make them active
    catEl.classList.add("active");
    if (subcatEl !== null) subcatEl.classList.add("active");

    // filter results
    const results = projectsJSON.filter(p =>
      p.category.includes(targetCategory)
    );

    // render 'em
    renderResults(results);
  };

  const filterSubcategory = target => {
    // remove subnav actives
    const actives = document.querySelectorAll(".filters__subnav li.active");
    actives.forEach(a => a.classList.remove("active"));

    // add active
    target.classList.add("active");

    const subcategoryName = target.getAttribute("data-name");

    // get results
    const results = projectsJSON.filter(p =>
      p.subcategory.includes(subcategoryName)
    );

    //   render 'em
    renderResults(results);
  };

  // initial first filter
  filterCategory('All');
};
