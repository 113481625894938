export default () => {
    const video = document.querySelector('.video');
    if (video === null) return;

    const videoEmbed = document.querySelector('.video__embed');
    const playButton = video.querySelector('.video__play');
    const closeButton = videoEmbed.querySelector('.video__close');
    const videoEl = videoEmbed.querySelector('video');
    const iframeEl = videoEmbed.querySelector('iframe');

    const openVideo = () => {
        document.body.classList.add('video-play');

        // if video, play
        if (videoEl !== null) videoEl.play();
    }

    const closeVideo = () => {
        document.body.classList.remove('video-play');

        // if video, pause
        if (videoEl !== null) videoEl.pause();

        // if iframe, reload
        if (iframeEl !== null) iframeEl.setAttribute('src', iframeEl.getAttribute('src'))
    }

    playButton.addEventListener('click', openVideo);
    closeButton.addEventListener('click', closeVideo);
  }
  