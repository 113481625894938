export default () => {
  const nav = document.querySelector('.contact__nav');
  if (nav === null) return;

  const links = nav.querySelectorAll("li");
  const contents = Array.from(document.querySelector('.contact__content').children);

  function switchMenu() {

    // find target tab
    const target = this.getAttribute('data-target');
    const targetEl = document.getElementById(target);

    // remove actives
    links.forEach(l => l.classList.remove('active'));
    contents.forEach(l => l.classList.remove('active'));

    // add actives
    this.classList.add('active');
    targetEl.classList.add('active');

  }

  links.forEach(l => l.addEventListener('click', switchMenu));

}
