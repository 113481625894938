import Displacer from "./displacer";

export default () => {
    const elements = document.querySelectorAll(".load-shader");
    if (elements === null) return;

    elements.forEach(parent => {
        const image = parent.querySelector("img");
        if (image === null) return;

        // Create shader over images
        const shader = new Displacer({
            parent,
            image,
            initialDisplacement: -0.6
        });

        // Animate on load
        setTimeout(() => shader.undistort(), 200)

    });

};
