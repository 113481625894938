/*--------------------------------------------------
A C E R B I S
--------------------------------------------------*/
require('intersection-observer')

import mediumZoom from 'medium-zoom'
import sal from 'sal.js'
import salAnimations from './js/salAnimations'
// import splittingAnimations from './js/splittingAnimations'

import homepage from './js/homepage'
import slider from './js/slider'
import nav from './js/nav'
import hotText from './js/hotText'
import gallery from './js/gallery'
import contact from './js/contact'
import video from './js/video'
import anchorScroll from './js/anchorScroll'
import SupahScroll from './js/supahscroll'
import projects from './js/projects'
import onLoadShader from './js/onLoadShader'


/*--------------------------------------------------
Splitting
--------------------------------------------------*/
// import "splitting/dist/splitting.css"
// import "splitting/dist/splitting-cells.css"
// import Splitting from "splitting"


/*--------------------------------------------------
Css
--------------------------------------------------*/
import './scss/main.scss'

import { TweenMax, Power3 } from 'gsap'


/*--------------------------------------------------
Set Height
--------------------------------------------------*/
document.documentElement.style.setProperty('--app-height', window.innerHeight + 'px')

/*------------------------------
Dom Ready
------------------------------*/
function domReady(fn) {
  document.addEventListener("DOMContentLoaded", fn)
  if (document.readyState === "interactive" || document.readyState === "complete" ) {
    fn()
  }
}


domReady(() => {
    if (window.innerWidth > 768) {
      window.supahscroll = new SupahScroll({
        speed: 0.17
      })
    }

    // // Browser detection
    // window.browser = browserDetect()
    // $('body').addClass(browser.name)
    // if (browser.os.toLowerCase().indexOf('os') !== -1) {
    //     $('body').addClass('os')
    // }
    // if (browser.os.toLowerCase().indexOf('windows') !== -1) {
    //     $('body').addClass('windows')
    // }
    // if (browser.mobile) {
    //     $('body').addClass('mobile')
    // }

    // Splitting()
    // splittingAnimations()

    salAnimations()
    sal({
      threshold: .3,
    })
    homepage()
    nav()
    contact()
    video()
    slider()
    anchorScroll()
    hotText()
    gallery()
    projects()
    onLoadShader()

    setTimeout(()=> {
      document.body.classList.add('ready')
    }, 300)
})