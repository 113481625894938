import Draggabilly from 'draggabilly'
import { Power4, Elastic, Circ } from 'gsap';
import mediumZoom from 'medium-zoom'
import isHover from './isHover'

const MathUtils = {
    lineEq: (y2, y1, x2, x1, currentVal) => {
        // y = mx + b 
        var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
        return m * currentVal + b;
    },
    lerp: (a, b, n) => (1 - n) * a + n * b,
    getRandomFloat: (min, max) => (Math.random() * (max - min) + min).toFixed(2)
};


let winsize;
const calcWinsize = () => winsize = { width: window.innerWidth, height: window.innerHeight };
calcWinsize();
window.addEventListener('resize', calcWinsize);

// Strip Item
class StripItem {
    constructor(el) {
        this.$ = { el: el };
        this.$.image = this.$.el.querySelector('.img-inner');
    }
}

// Images strip
class Strip {
    constructor(el) {
        this.$ = { el: el };
        this.$.strip = this.$.el.querySelector('.strip');
        this.items = [];
        [...this.$.strip.querySelectorAll('.strip__item')].forEach(item => this.items.push(new StripItem(item)));
        // The draggable container
        this.$.draggable = this.$.el.querySelector('.draggable');
        // The width of the draggable container (also the strip container)
        this.draggableWidth = this.$.draggable.offsetWidth;
        // The total amount that we can drag the draggable container, so that both the first and last image stay next to the viewport boundary (left and right respectively)
        this.maxDrag = this.draggableWidth < winsize.width ? 0 : this.draggableWidth - winsize.width;
        // The current amount (in pixels) that was dragged
        this.dragPosition = 0;
        // Initialize the Draggabilly
        this.draggie = new Draggabilly(this.$.draggable, { axis: 'x' });

        this.gallery = document.querySelector('.gallery');

        this.init();
        this.initEvents();
    }
    init() {
        this.renderedStyles = {
            position: { previous: 0, current: this.dragPosition },
            scale: { previous: 1, current: 1 },
            imgScale: { previous: 1, current: 1 },
            opacity: { previous: 1, current: 1 },
        };

        const speed = window.innerWidth > 400 ? 0.1 : 0.25

        this.render = () => {
            this.renderId = undefined;

            for (const key in this.renderedStyles) {
                this.renderedStyles[key].previous = MathUtils.lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, speed); // 0.1
            }

            TweenMax.set(this.$.strip, { x: this.renderedStyles.position.previous, ease: Circ.easeOut });

            for (const item of this.items) {
                TweenMax.set(item.$.el, { scale: this.renderedStyles.scale.previous, opacity: this.renderedStyles.opacity.previous });
                TweenMax.set(item.$.image, { scale: this.renderedStyles.imgScale.previous });
            }

            if (!this.renderId) {
                this.renderId = requestAnimationFrame(() => this.render());
            }
        };
        this.renderId = requestAnimationFrame(() => this.render());
    }
    initEvents() {
        this.onDragStart = () => {
            this.renderedStyles.scale.current = 0.9;
            this.renderedStyles.imgScale.current = 1.3;
            // this.renderedStyles.opacity.current = 0.3;


        };

        this.onDragMove = (event, pointer, moveVector) => {
            // The possible range for the drag is draggie.position.x = [-maxDrag,0 ]
            if (this.draggie.position.x >= 0) {
                // the max we will be able to drag is winsize.width/2
                this.dragPosition = MathUtils.lineEq(0.5 * winsize.width, 0, winsize.width, 0, this.draggie.position.x);
            }
            else if (this.draggie.position.x < -1 * this.maxDrag) {
                // the max we will be able to drag is winsize.width/2
                this.dragPosition = MathUtils.lineEq(0.5 * winsize.width, 0, this.maxDrag + winsize.width, this.maxDrag, this.draggie.position.x);
            }
            else {
                this.dragPosition = this.draggie.position.x;
            }
            this.renderedStyles.position.current = this.dragPosition;

        };

        this.onDragEnd = () => {
            // reset draggable if out of bounds.
            if (this.draggie.position.x > 0) {
                this.dragPosition = 0;
                this.draggie.setPosition(this.dragPosition, this.draggie.position.y);
            }
            else if (this.draggie.position.x < -1 * this.maxDrag) {
                this.dragPosition = -1 * this.maxDrag;
                this.draggie.setPosition(this.dragPosition, this.draggie.position.y);
            }
            this.renderedStyles.position.current = this.dragPosition;
            this.renderedStyles.scale.current = 1;
            this.renderedStyles.imgScale.current = 1;
            // this.renderedStyles.opacity.current = 1;



        };


        this.onClick = (event, pointer) => {

            const target = this.items.filter(i => isHover(pointer, i.$.image))[0]

            const zoom = mediumZoom(target.$.image)
            zoom.open()

            // Dopo 400ms attacco l'evento per la chiusura dell'immagine
            // Perché se si chiude normalmente flickera
            // Setto un'opacity
            setTimeout(() => {
                const img = document.querySelector('.medium-zoom-image--opened')

                img.addEventListener('click', () => {
                    TweenMax.to(img, .3, {
                        opacity: 0,
                        onComplete: ()=> {
                            img.removeEventListener('click')
                        }
                    })
                })
                
            }, 60)
        };

        this.draggie.on('pointerDown', this.onDragStart);
        this.draggie.on('dragMove', this.onDragMove);
        this.draggie.on('pointerUp', this.onDragEnd);
        this.draggie.on('staticClick', this.onClick);

        window.addEventListener('resize', () => {
            this.maxDrag = this.draggableWidth < winsize.width ? 0 : this.draggableWidth - winsize.width;
            if (Math.abs(this.dragPosition) + winsize.width > this.draggableWidth) {
                const diff = Math.abs(this.dragPosition) + winsize.width - this.draggableWidth;
                // reset dragPosition
                this.dragPosition = this.dragPosition + diff;
                this.draggie.setPosition(this.dragPosition, this.draggie.position.y);
            }
        });
    }
}

const scrollFade = () => {

    window.addEventListener('scroll', function() {
        const element = document.querySelector('.gallery');
        const position = element.getBoundingClientRect();

        // checking for partial visibility
        if(position.top > -200 && position.top < 100) {
            document.body.classList.add('in-gallery')
        } else {
            document.body.classList.remove('in-gallery')
        }
    });
}


export default () => {
    const element = document.querySelector('.strip-outer');
    if (element === null) return;

    // Init gallery
    const strip = new Strip(element);

    // Init scroll fade
    scrollFade();

}